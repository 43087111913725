const buttons = {
  primary: {
    fontSize: 1,
    fontWeight: "normal",
    color: "white",
    bg: "primary",
    borderRadius: "default",
    cursor: "pointer",
    transition: "all 300ms ease",
    "&:hover": { bg: "primaryLight" }
  },
  categorieToggle: {
    variant: "buttons.primary",
    bg: "background",
    color: "primary",
    border: "1px solid",
    px: 6,
    borderColor: "gray30",
    "&:hover": { color: "primaryLight" }
  },
  golden: {
    variant: "buttons.primary",
    bg: "golden",
    "&:hover": { bg: "goldLight" }
  },
  store: {
    variant: "buttons.primary",
    bg: "black",
    py: 1,
    "&:hover": { bg: "#444444" }
  },
  outline: {
    variant: "buttons.primary",
    color: "primary",
    bg: "transparent",
    borderColor: "primary",
    border: "1px solid",
    "&:hover": { bg: "transparent" }
  },
  secondary: {
    variant: "buttons.primary",
    color: "text",
    bg: "secondary"
  },
  transparent: {
    variant: "buttons.primary",
    transition: "all 300ms ease",
    color: "inherit",
    bg: "transparent",
    "&:hover": { bg: "transparent" }
  },
  white: {
    variant: "buttons.primary",
    transition: "all 300ms ease",
    color: "primary",
    bg: "white",
    "&:hover": { bg: "primary", color: "white" }
  },
  ninja: {
    cursor: "pointer",
    color: "inherit",
    bg: "transparent",
    m: 0,
    p: 0,
    border: "none",
    "&:hover": {
      bg: "transparent",
      opacity: 1
    }
  }
};

export default buttons;
