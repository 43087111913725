import { theme } from "../cms/data/settings";
const colors = {
  transparent: "transparent",
  muted: "#f6f6f9",
  gray: "#A0A9BA",
  gray50: "rgba(160, 169, 186, 0.5)",
  gray30: "rgba(160, 169, 186, 0.3)",
  gray10: "rgba(160, 169, 186, 0.1)",
  grayDark: "#233239",
  grayLight: "#F5F5F5",
  highlight: "rgba(160, 169, 186, 0.5)",
  primary: "#7CAF41",
  primaryLight: "#9DCB21",
  secondary: "#ff4400",
  text: "#2F4858",
  background: "#ffffff",
  danger: "#dd4b39",
  warning: "#ff8d24",
  success: "#009F78",
  footer: "#3c3c3c",
  pink: "#F761A1",
  purple: "#8C1BAB",
  goldDark: "#FBAB00",
  goldLight: "#F7CE68",
  golden: "#ebc700",
  gradiantPrimary: "linear-gradient(90deg, #9DCB21 0%, #7CAF41 100%);",
  gradiantText: "linear-gradient(90deg, #2F4858 0%, #406278 100%);",
  gradiantPurple: "linear-gradient(90deg, #F761A1 0%, #8C1BAB 100%);",
  gradiantGold: "linear-gradient(90deg, #FBAB00 0%, #F7CE68 100%);",
  gradiantPlatinum: "linear-gradient(90deg, #001621 0%, #3D5068 100%);",
  ...theme.colors
};

export default colors;
