import colors from "./colors";
const variants = {
  avatar: {
    width: "avatar",
    height: "avatar",
    borderRadius: "circle"
  },
  categorieCircle: {
    display: "inline-block",
    margin: "0 auto",
    bg: "primary",
    width: "48px",
    height: "48px",
    borderRadius: 9999,
    color: "#fff"
  },
  badges: {
    display: "inline-block",
    px: 2,
    py: 1,
    borderRadius: 9999,
    fontSize: 0
  },
  badge: {
    primary: {
      variant: "variants.badges",
      color: "white",
      bg: "primary"
    },
    primaryGradiant: {
      variant: "variants.badges",
      color: "white",
      bg: "primary",
      background: colors.gradiantPrimary
    },
    gradiantText: {
      variant: "variants.badges",
      color: "white",
      bg: "text",
      background: colors.gradiantText
    },
    gradiantPurple: {
      variant: "variants.badges",
      color: "white",
      bg: "purple",
      background: colors.gradiantPurple
    },
    offre47: {
      variant: "variants.badges",
      color: "white",
      bg: "purple",
      background: colors.gradiantPurple
    },
    offre48: {
      variant: "variants.badges",
      color: "white",
      bg: "primary",
      background: colors.gradiantPrimary
    },
    offre180: {
      variant: "variants.badges",
      color: "white",
      bg: "goldDark",
      background: colors.gradiantGold
    }
  },
  card: {
    p: 2,
    bg: "background",
    boxShadow: "card"
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    fontWeight: "inherit"
  },
  linktext: {
    color: "inherit",
    textDecoration: "inherit"
  },
  darkBg: {
    backgroundColor: "primary",
    color: "background"
  },
  bgImg: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  squareBox: {
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      paddingBottom: "100%"
    }
  },
  nav: {
    fontSize: 1,
    fontWeight: "bold",
    display: "inline-block",
    p: 2,
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus,.active": {
      color: "primary"
    }
  },
  searchBar: {
    backgroundColor: "primary",
    background: colors.gradiantPrimary
  },
  navbar: {
    width: ["100%"],
    transition: "all 300ms ease",
    backgroundColor: "white",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
    "&.sticky": {
      backgroundColor: "background",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)"
    },
    color: "text",
    a: {
      color: "text"
    },
    ".logo": {
      display: "block",
      lineHeight: 0
    },
    position: "absolute",
    zIndex: 10
  },
  container: {
    width: "100%",
    maxWidth: ["100%", "100%", "100%", "960px"],
    minWidth: ["0", "0", "0", "960px"],
    m: "0 auto",
    flexbox: true,
    px: [3, 3, 6]
  },
  containerFluid: {
    width: "100%",
    m: "0 auto",
    flexbox: true,
    px: [3, 3, 6]
  },
  grid: {
    flexWrap: "wrap",
    mx: [-3, -3, -6]
  },
  gridItem: {
    px: [3, 3, 6]
  },
  section: {
    width: "100%",
    pt: [10, 10, 11],
    pb: [10, 10, 11]
  }
};

export default variants;
