module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pay.nc","short_name":"Pay.nc","start_url":"/","background_color":"#003957","theme_color":"#003957","icon":"static/img/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"dc7cc825909062f7581ce28e3871c6ff"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","manualInit":true,"enableIdentityWidget":true,"htmlTitle":"Pay.nc Admin"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
