import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, SiteWrapper } from "./src/theme/Site.style";
import theme from "./src/theme";
import styled from "styled-components";
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import PropTypes from "prop-types";

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
  background-color: rgba(0, 0, 0, 0.8);
`;
const Wrapper = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SiteWrapper>
        <ModalProvider backgroundComponent={FadingBackground}>{element}</ModalProvider>
      </SiteWrapper>
    </ThemeProvider>
  );
};

Wrapper.propTypes = {
  element: PropTypes.node
};
export default Wrapper;
